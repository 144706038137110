<template>
  <div>
    <v-select
      :items="items"
      outlined
      dense
      :messages="$t('ec.action.update_payment_status.help')"
      v-model="data.status"
    ></v-select>

    <v-checkbox
      v-if="notifyFeature"
      :label="$t('ec.action.send_notify')"
      v-model="data.notify"
    ></v-checkbox>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    popupConfig: Object,
  },
  data: () => ({
    data: {
      status: null,
      notify: false,
    },
  }),
  mounted() {
    this.data.status = this.originPaymentStatus
  },
  computed: {
    originPaymentStatus() {
      return this.popupConfig.originPaymentStatus
    },
    items() {
      return this.$paymentStatusEditable.map(status => ({
        text: this.$t(`ec.data.payment_status.${status}`),
        value: status,
      }))
    },
    notifyFeature() {
      if(this.data.status === this.$paymentConstants.STATUS_PAID) return true
      if(this.data.status === this.$paymentConstants.STATUS_FAILURE) return true
      return false
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', this.data)
      },
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
